export const onlyNumber = (event, float = false) => {
  const keysAllowed = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'Backspace',
    'Escape',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
    'Control',
    'Tab',
  ]
  const ctrlAllowed = [
    // Allow: Ctrl+A
    65,
    // Allow: Ctrl+C
    67,
    // Allow: Ctrl+X
    88,
    // Allow: Ctrl+V
    86,
  ]
  if (float) {
    keysAllowed.push('.')
  }
  const keyPressed = event.key
  const keyCode = event.keyCode
  if (keysAllowed.includes(keyPressed) || (event.ctrlKey === true && ctrlAllowed.includes(keyCode))) {
    return
  } else {
    event.preventDefault()
  }
}
